<template lang="pug">
  b-row.mb-5
    b-col
      router-view(@firmware-changed='reloadMachinesPanel')
</template>

<script>
export default {
  name: 'manager-machines-firmware',
  methods: {
    reloadMachinesPanel() {
      this.$parent.$refs.machinesPanel.panelReload()
    },
  },
}
</script>
